<template>
  <div class="d-flex align-items-center justify-content-center">
    <b-img
      fluid
      src="@/assets/images/logo/logo.gif"
      alt="Login V2"
      :width="width"
    />
  </div>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    width: {
      type: String,
      default: "200"
    }
  },
}
</script>
