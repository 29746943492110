<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <!-- <b-link class="brand-logo d-block w-100 mb-5">
      <app-logo />
    </b-link> -->

    <div class="misc-inner p-2 p-sm-3 mt-5">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Page Not Found 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          Oops! 😖 The requested URL was not found on this server.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Back to home
        </b-button>

        <!-- image -->
        <component
          :is="imgUrl"
          fluid
          alt="Error page"
        />
        
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'

import store from '@/store/index'
import AppLogo from '@core/layouts/components/Logo.vue'

import ErrorSVG from "@/assets/images/pages/error.svg";
import ErrorDarkSVG from "@/assets/images/pages/error-dark.svg";

export default {
  components: {
    AppLogo,
    BLink,
    BButton,
    BImg,
    ErrorSVG,
    ErrorDarkSVG
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return ErrorDarkSVG
      }
      return ErrorSVG
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
